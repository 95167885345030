<template>
  <div class="container-fluid movie-detail mt-2 mt-md-3 mt-lg-3 px-0" v-if="currentMovie">

    <div class="px-0 mb-4 d-sm-block d-xs-block d-md-none">
      <img class="img-fluid" :src="currentMovie.horizontal_poster" :alt="currentMovie.title">
    </div>

    <div class="container-fluid d-flex d-md-row header-content"
      :style="{ 'background-image': 'url(' + currentMovie.horizontal_poster + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover' }">
      <div class="header-content-main d-flex px-4 p-md-0 p-lg-0 p-xl-0">
        <div class="px-0 px-md-3 main-content">

          <img class="d-none d-sm-none d-xs-none d-md-block secondary-image" :src="currentMovie.movie_logo" />

          <div class="py-3 pt-md-2 pt-xl-3 pt-xxl-5">
            <!-- <h3 class="movie-title">{{ currentMovie.title }}</h3> -->
            <div class="movie-generue meta text-muted">
              {{ currentMovieMeta }}
            </div>
            <div class="sinopsis mt-4" å>
              <p class="movie-description" v-html="currentMovie.description"> </p>
            </div>
            <div class="crew my-2">
              <p class="movie-extra-detail text-muted p-0 m-0">{{ $t('Director:') }} {{ currentMovie.director }}</p>
              <p class="movie-extra-detail text-muted p-0 m-0">{{ $t('Reparto:') }} {{ currentMovie.cast }}</p>
            </div>


            <div id="proximasBtn" class="prevent-select proximas d-sm-block d-xs-block d-md-none d-lg-none d-xl-none"
              @click="scrollToProximas">
              Próximas emisiones<svg height="29" viewBox="0 0 48 48" width="28" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12" fill="#ffffff" />
                <path d="M0-.75h48v48h-48z" fill="none" />
              </svg>
            </div>

          </div>

          <div class="my-4 my-md-2">
            <button
              class="m-0 py-1 btn btn-lg text-uppercase btn-outline-primary d-block btn-outline-light-blue watch-trailer-btn"
              @click="openVideo(currentMovie)" style="border-width: 2px !important; font-size: 18px;">
              <i class="bi bi-play-fill playicon button-text"></i> {{ $t('Ver trailer') }}
            </button>
          </div>

          <div class="pt-0 pt-md-0 pt-xl-5">
            <!-- <div id="proximasBtn" class="prevent-select proximas d-none d-sm-none d-xs-none d-md-block"
              :class="proximasBtnClicked ? 'bg-primary' : ''" @click="scrollToProximas"> -->
            <div id="proximasBtn" class="prevent-select proximas d-none d-sm-none d-xs-none d-md-block"
              @click="scrollToProximas">
              Próximas emisiones<svg height="29" viewBox="0 0 48 48" width="28" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12" fill="#ffffff" />
                <path d="M0-.75h48v48h-48z" fill="none" />
              </svg>
            </div>
          </div>

          <img class="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none secondary-image p-0 m-0"
            :src="currentMovie.movie_logo" />

        </div>




      </div>



    </div>


    <div id="proximas-emisiones" class="bg-white container-fluid py-3 text-dark"
      v-if="currentMovie.upcoming_broadcasts.length > 0">

      <div class="container">
        <h4>{{ $t('Próximas emisiones') }}</h4>

        <div class="programming-block row">
          <div class="programming-line col-12 col-sm-4" v-for="(d, i) in currentMovie.upcoming_broadcasts"
            v-bind:key="i">
            <h5 class="text-capitalize">{{ d.start_time.toDayMonthDate(locale) }}</h5>
            <span class="asset">
              <span class="programming-asset">
                {{ d.start_time.toProgrammingDate(locale) }}
              </span>
            </span>
          </div>
        </div>

        <router-link class="read-more pt-3 text-left d-block" :to="{ name: 'programming' }">
          {{ $t('Ver programación completa') }} >
        </router-link>
      </div>
    </div>

    <div v-else class="container-fluid mb-4"></div>

    <div class="container" v-if="false && currentMovie.related_movies && currentMovie.related_movies.data">
      <h4 class="my-4">{{ $t('Títulos Similares:') }}</h4>
      <home-movies :movies="currentMovie.related_movies.data"></home-movies>
    </div>

  </div>

</template>

<script>


import HomeMovies from "../components/home/HomeMovies";
import { mapGetters } from "vuex";

export default {
  name: "MoviePage",
  props: {
    slug: {
      required: false,
      type: undefined
    }
  },
  components: { HomeMovies },
  computed: {
    ...mapGetters(["locale"]),
    genBlurredBg: function () {
      return {
        background: 'url(' + this.currentMovie.horizontal_poster + ') no-repeat center center',
        backgroundSize: 'cover',
        filter: 'blur(10px)'
      }
    },
    currentMovieMeta() {
      var texts = [];

      if (this.currentMovie.tv_rating) {
        texts.push(this.currentMovie.tv_rating);
      }

      var genresTexts = [];
      this.currentMovie.genres.forEach((el) => {
        if (el.name) {
          genresTexts.push(el.name);
        }
      });
      if (genresTexts.length > 0) {
        texts.push(genresTexts.join(' '));
      }

      if (this.currentMovie.duration) {
        texts.push(this.currentMovie.duration);
      }

      if (this.currentMovie.year) {
        texts.push(this.currentMovie.year);
      }

      if (this.currentMovie.country) {
        texts.push(this.currentMovie.country);
      }

      return texts.join(' | ');
    }
  },
  methods: {
    fetchMovie: function () {
      this.$store.dispatch("fetchMovie", this.slug).then(data => {
        this.currentMovie = data;
        console.log("movie data", this.currentMovie);
      });
    },
    scrollToProximas: function () {
      const proximasElement = document.getElementById('proximas-emisiones');
      this.proximasBtnClicked = true
      if (proximasElement) {
        proximasElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  data() {
    return {
      currentMovie: null,
      fulldesc: false,
      proximasBtnClicked: false
    }
  },
  mounted() {
    this.fetchMovie();
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";

// .secondary-image {
//   height: auto;
//   width: 80%;
// }

.header-content {
  padding: 0px;
}

.content-container {
  margin-bottom: 40px;
}

.proximasBtnElementClick {
  background-color: $light-blue;
}

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.watch-trailer-btn {
  width: 300px;
}


.proximas {
  background: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
  width: fit-content;
}


@media only screen and (min-width: 768px) {
  .header-content {
    height: 100vh;
    position: relative;
  }

  .main-content {
    width: 50%;
    height: 100%;
    padding: 0% 6% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background: linear-gradient(to right, #020916 30%, transparent 100%);
    background: linear-gradient(90deg, #181818 10%, hsla(0, 0%, 9%, .98) 20%, hsla(0, 0%, 9%, .97) 25%, hsla(0, 0%, 9%, .95) 35%, hsla(0, 0%, 9%, .94) 40%, hsla(0, 0%, 9%, .92) 45%, hsla(0, 0%, 9%, .9) 50%, hsla(0, 0%, 9%, .87) 55%, hsla(0, 0%, 9%, .82) 60%, hsla(0, 0%, 9%, .75) 65%, hsla(0, 0%, 9%, .63) 70%, hsla(0, 0%, 9%, .45) 75%, hsla(0, 0%, 9%, .27) 80%, hsla(0, 0%, 9%, .15) 85%, hsla(0, 0%, 9%, .08) 90%, hsla(0, 0%, 9%, .03) 95%, hsla(0, 0%, 9%, 0));
  }

  .image-container {
    position: relative;
    text-align: right;
  }

  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Stretch image to fill container */
    height: 100%;
    z-index: -1;
    /* Place image behind the gradient */
  }

  .image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #020916 1%, transparent 50%);
    /* Gradient overlay */
    z-index: 2;
  }
}

@media (max-width: 767px) {
  .header-content {
    flex-direction: column;
  }

  .header-content,
  .container {
    background-image: none !important;
  }

  .main-content {
    display: flex;
    flex-direction: column-reverse;
    width: 100% !important;
  }

  // .watch-trailer-btn {
  //   margin: auto !important;
  // }

}

@media (max-width: 1920px) and (min-width: 1024px) {
  .header-content {
    width: 100% !important;
    padding: 0px !important;
    height: 100lvh;
    position: relative;
  }

  // .watch-trailer-btn {
  //   width: 60%;
  // }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .header-content {
    width: 100% !important;
    padding: 0px !important;
    height: 40lvh;
    position: relative;
  }

  // .watch-trailer-btn {
  //   width: 80%;
  // }

  .button-text {
    font-size: 14px;
  }

  .main-content {
    width: 80%;
  }

  .secondary-image {
    width: 40%;
  }

  .movie-title {
    font-size: 16px;
  }

  .movie-extra-detail,
  .movie-generue,
  .movie-description {
    font-size: 14px;
  }

}



@media (min-width: 1921px) {
  .header-content {
    width: 1920px;
    height: 60vh !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .main-content {
    background: linear-gradient(to right, #020916 30%, transparent 100%);
  }

}



@media (min-width: 1920px) {
  .header-content {
    padding: 0px !important;
    height: 87vh;
    position: relative;
  }

  .container {
    width: 1920px !important;
  }
}

@media(min-width: 768px) {
  .proximas:hover {
    background: $light-blue;
  }
}

@media(max-width: 768px) {
  .proximas:active {
    background: $light-blue;
  }
}


.programming-block {
  list-style: none;
  padding: 0;
  margin: 0px !important;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;

  .programming-line {
    border-left: 1px solid gray;
    padding: 0 1.5em;
    margin: 1.5em 0;
  }
}

.movie-detail {
  .read-more {
    &:hover {
      color: $dark-blue;
    }
  }
}

.playicon {
  font-size: 1.5em;
  position: relative;
  top: .10em;
}

.vertrailer {
  background: none;
  border: 3px solid $white;
  border-radius: 15px;
  color: white;
  font-size: 1.4em;
  padding: .5em 1em;
  transition: all 0.3s;
  text-transform: uppercase;

  &:hover {
    background: $light-blue;
  }
}
</style>